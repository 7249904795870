@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ce-block h1 {
  font-size: 2rem;
  font-weight: bold;
}

.ce-block h2 {
  font-size: 1.75rem;
  font-weight: bold;
}

.ce-block h3 {
  font-size: 1.5rem;
  font-weight: bold;
}

.ce-block h4 {
  font-size: 1.25rem;
  font-weight: bold;
}

.ce-block h5 {
  font-size: 1.1rem;
  font-weight: bold;
}

.ce-block h6 {
  font-size: 1rem;
  font-weight: bold;
}

.ce-toolbar__plus,
.ce-toolbar__settings-btn {
  display: block !important;
}
.ce-code__textarea{
  background-color: black;
}